import React from 'react';
import Layout from '../components/Layout';
import ProductsBanner from '../components/ProductsBanner';
import VideoTextCard from '../components/VideoTextCard';
import TAHubContentList from '../components/TAHubContentList';
import HomeLogos from '../components/HomeLogos';
import BlockIconText from '../components/BlockIconText';
import TABanner from '../images/ta-banner-image.svg';
import VideoHolder from '../images/tahub-video.svg';

export default () => (
  <Layout current="products" subCurrent="tahub">
    <ProductsBanner
      title="TA Hub 监测分析平台"
      description="如何对广告投放有效监测，同时甄别无效流量？"
      description1="TA Hub提供埋点数据采集能力，并针对埋点数据展开分析与洞察。还可与Convertlab其他产品无缝集成，获得一体化全链路数据完整采集并分析的体验，助力广告投放。"
      img={TABanner}
    />
    <VideoTextCard title="更全面更灵活的营销活动监测分析" image={VideoHolder} imgWidth={341} imgHeight={311}>
      <BlockIconText content="领先于市场通用能力，实现自定义跨页面元素的属性传递" color="blue" />
      <BlockIconText content="率先实现小程序的可视化埋点能力，并自动集成友商埋点" color="green" />
      <BlockIconText content="埋点SDK的源码免费提供，更开源，成本更友好" color="blue" />
      <BlockIconText content="与Convertlab旗下Ad Hub、DM Hub、Data Hub无缝集成" color="green" />
      <BlockIconText content="反作弊能力加持，甄别无效流量，具备隐私保护合规管理" color="blue" />
    </VideoTextCard>
    <TAHubContentList />
    <HomeLogos />
  </Layout>
);
