import React from 'react';
import Container from './Container';
import ImageText from './ImageText';
import ImageTextDescription from './ImageTextDesctiption';
import TAHub1 from '../images/tahub-1.svg';
import TAHub2 from '../images/tahub-2.svg';
import TAHub3 from '../images/tahub-3.svg';
import TAHub4 from '../images/tahub-4.svg';

export default () => {
  return (
    <div className="content-list-wrapper tahub">
      <div>
        <section className="image-text-section">
          <Container fluid="xl">
            <ImageText image={TAHub1} imgWidth={400} imgHeight={380} title="全埋点高效精准采集APP的页面生命周期事件">
              <ImageTextDescription
                label="自动化全埋点"
                content="基于iOS，Android操作系统提供的生命周期函数添加自动埋点"
              />
              <ImageTextDescription
                label="自动上报"
                content="ConvertLab SDK支持对前后链路事件进行捕获并上报"
              />
              <ImageTextDescription
                label="埋点可追溯"
                content="可通过自定义条件对事件进行筛选向前追溯"
              />
            </ImageText>
          </Container>
        </section>
        <section className="image-text-section">
          <Container fluid="xl">
            <ImageText image={TAHub2} imgWidth={510} imgHeight={380} title="可视化埋点随时修改配置新增埋点 " position="right">
              <ImageTextDescription label="不依赖开发人员" content="自助在网页端灵活圈选需要埋点的元素" />
              <ImageTextDescription label="支持圈选字段属性" content="属性支持“文本”、“数值”、“日期时间”类型，支持自定义其他属性" />
              <ImageTextDescription label="支持自定义属性跨页面传递" content="运营人员可便捷的圈选跨页面的参数，完成多层级活动页面的参数记录" />
              <ImageTextDescription label="隐私授权管理" content="TA Hub提供的所有的SDK，都针对《个人信息保护法》进行适配确保安全合规" />
            </ImageText>
          </Container>
        </section>
        <section className="image-text-section">
          <Container fluid="xl">
            <ImageText image={TAHub3} imgWidth={426} imgHeight={353} title="数据看板快速区分广告来源及流量">
              <ImageTextDescription label="用户数据" content="包括用户使用情况、用户趋势、终端属性和网页分析" />
              <ImageTextDescription label="事件分析" content="记录所有的行为数据并分析，了解广告、转化行为的变化趋势" />
              <ImageTextDescription label="漏斗分析" content="广告流程转化率分析，关注具体行为转化流程中流失较多的步骤" />
              <ImageTextDescription label="归因分析" content="统计各媒体来源的曝光、点击、转化率等，辅助判断媒体贡献率" />
              <ImageTextDescription label="热力图" content="通过采集用户的点击事件，可视化地呈现用户点击事件的元素在应用中的位置，实现页面热力图分析" />
            </ImageText>
          </Container>
        </section>
        <section className="image-text-section">
          <Container fluid="xl">
            <ImageText image={TAHub4} imgWidth={510} imgHeight={380} title="反作弊策略甄别无效流量 " position="right">
              <ImageTextDescription label="以行业标准为基石的无效流量监测和过滤产品" />
              <ImageTextDescription label="不断优化评定模型，不断挖掘异常流量特征" />
              <ImageTextDescription label="丰富的判断类别帮助广告主明晰流量质量" />
              <ImageTextDescription label="常见的五种案例包括非法参数、全局异常频次、异常地理分布、异常网路、异常设备" />
            </ImageText>
          </Container>
        </section>
      </div>
    </div>
  );
};
